import { useRef } from 'react';
import { getCenteredPopupFeatures, HostAuthPopupOverlay } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import { useGlobalContext } from '../globalContext';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';

export const useRedirectPopup = (type: HostAuthPopupOverlay) => {
  const { setIsLoading } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const popupRef = useRef<Window | null>(null);

  const openPopupWindow = (redirectURL: string) => {
    const features = getCenteredPopupFeatures(500, 600);
    popupRef.current = window.open(redirectURL, 'mgp-sdk-popup', features);
    const timer = setInterval(() => {
      if (popupRef.current?.closed) {
        popupRef.current = null;
        setIsLoading(false);
        clearInterval(timer);

        dispatchMessageToApp(CheckoutSdkFrameEventType.Cancel, undefined);
      }
    }, 1000);
    dispatchMessageToApp(CheckoutSdkFrameEventType.ShowPopupWindowOverlay, type);
  };

  const closePopupWindow = () => {
    popupRef.current?.close();
    popupRef.current = null;
  };

  const openPopupModal = (redirectURL: string) => {
    dispatchMessageToApp(CheckoutSdkFrameEventType.OpenHostAuthPopupModal, {
      url: redirectURL,
      overlay: '3ds',
    });
  };

  const updatePopupUrl = (redirectURL: string) => {
    popupRef.current?.location.replace(redirectURL);
  };

  const openPreloaderPopup = () => {
    openPopupWindow(`${window.location.href}&preloading=paypal`);
  };

  return {
    openPopupWindow,
    closePopupWindow,
    openPopupModal,
    updatePopupUrl,
    openPreloaderPopup,
  };
};
