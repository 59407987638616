export enum DebuggerLogType {
  SDK_INITIALIZED = 'SDK_INITIALIZED',
  OPTIONS_UPDATED = 'OPTIONS_UPDATED',
  PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED',
  '3DS_AUTHENTICATION_REQUESTED' = '3DS_AUTHENTICATION_REQUESTED',
  '3DS_AUTHENTICATION_COMPLETED' = '3DS_AUTHENTICATION_COMPLETED',
  '3DS_AUTHENTICATION_FAILED' = '3DS_AUTHENTICATION_FAILED',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_ERRORED = 'PAYMENT_ERRORED',
  ERROR_DETAILS = 'ERROR_DETAILS',
  TOKENIZATION_STARTED = 'TOKENIZATION_STARTED',
  TOKENIZATION_COMPLETED = 'TOKENIZATION_COMPLETED',
  TOKENIZATION_FAILED = 'TOKENIZATION_FAILED',
  GOOGLE_PAY_INITIALIZED = 'GOOGLE_PAY_INITIALIZED',
  GOOGLE_PAY_SCRIPT_LOAD_FAILED = 'GOOGLE_PAY_SCRIPT_LOAD_FAILED',
  NETHONE_PROFILER_INITIALIZED = 'NETHONE_PROFILER_INITIALIZED',
  NETHONE_PROFILER_SCRIPT_LOAD_FAILED = 'NETHONE_PROFILER_SCRIPT_LOAD_FAILED',
  CARD_REGISTRATION_STARTED = 'CARD_REGISTRATION_STARTED',
  CARD_REGISTRATION_COMPLETED = 'CARD_REGISTRATION_COMPLETED',
  CARD_REGISTRATION_FAILED = 'CARD_REGISTRATION_FAILED',
  CARD_REGISTRATION_ERRORED = 'CARD_REGISTRATION_ERRORED',
  MISSING_OPTIONS_ERROR = 'MISSING_OPTIONS_ERROR',
  APPLE_PAY_INITIALIZED = 'APPLE_PAY_INITIALIZED',
  APPLE_PAY_AUTHORIZE_REQUEST_STARTED = 'APPLE_PAY_AUTHORIZE_REQUEST_STARTED',
  APPLE_PAY_AUTHORIZE_REQUEST_COMPLETED = 'APPLE_PAY_AUTHORIZE_REQUEST_COMPLETED',
  APPLE_PAY_AUTHORIZE_REQUEST_FAILED = 'APPLE_PAY_AUTHORIZE_REQUEST_FAILED',
  CREATE_REGISTRATION_REQUEST_ERROR = 'CREATE_REGISTRATION_REQUEST_ERROR',
  CREATE_CARD_PAY_IN_REQUEST_ERROR = 'CREATE_CARD_PAY_IN_REQUEST_ERROR',
  CREATE_PAYPAL_PAY_IN_REQUEST_ERROR = 'CREATE_PAYPAL_PAY_IN_REQUEST_ERROR',
  CREATE_GOOGLE_PAY_PAY_IN_REQUEST_ERROR = 'CREATE_GOOGLE_PAY_PAY_IN_REQUEST_ERROR',
  BIN_LOOKUP_FETCHED = 'BIN_LOOKUP_FETCHED',
  BIN_LOOKUP_ERROR = 'BIN_LOOKUP_ERROR',
}
