import { AuthorizeApplePayResult, CreateApplePayPaymentData } from '@mangopay/sdk-payment-methods';
import { TypedError } from '@mangopay/checkout-sdk-core';
import {
  CheckoutSdkFrameEventType,
  CheckoutSdkHostEventType,
  DebuggerLogType,
} from '@mangopay/checkout-sdk-hosted-core';
import { useGlobalContext } from '../../globalContext';
import { PaymentStatus } from '../../common';
import { usePaymentResultState } from '../usePaymentResultState';
import { useSentryDebugger } from '../../sentryLogger';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';
import { CreateApplePayPayInCompleteEvent } from './types';

/* Hook to show success state or failed state after tokenization is complete */
export const useApplePayPaymentComplete = () => {
  const { profilingAttemptReference, setIsLoading, options } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { addBreadcrumb, logEvent } = useSentryDebugger();
  const { handleErrorState, handleCancelState } = usePaymentResultState();
  const handleCreateApplePayPayInComplete = (event: MessageEvent<CreateApplePayPayInCompleteEvent>) => {
    if (event.data.eventType === CheckoutSdkHostEventType.CreateApplePayPaymentComplete) {
      const result = event.data.data;
      const isError = typeof result.errors !== 'undefined' || result.Status === PaymentStatus.Error;
      if (isError) {
        handleErrorState({
          declineMessage: result.Message || 'Payment failed',
          paymentMethod: 'apple_pay',
          declineCode: result.Type,
          errors: result.errors,
        });
      } else {
        dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentComplete, event.data.data);
      }
      setIsLoading(false);
    }
  };

  const handleAuthorizeApplePayComplete = (result: AuthorizeApplePayResult): void => {
    const errorResult = result as TypedError;
    const applePayResult = result as ApplePayJS.ApplePayPaymentToken;
    const isCancel = errorResult.Status === PaymentStatus.Canceled;
    const isError =
      errorResult.Status === PaymentStatus.Error ||
      !applePayResult.paymentData ||
      !applePayResult.paymentMethod ||
      !applePayResult.transactionIdentifier;

    if (isCancel) {
      handleCancelState();
      return;
    }

    if (isError) {
      handleErrorState({
        declineMessage: errorResult.ResultMessage || 'error.unknown',
        status: errorResult?.Status,
        declineCode: errorResult?.ResultCode,
        paymentMethod: 'apple_pay',
      });
      return;
    }
    addBreadcrumb(DebuggerLogType.TOKENIZATION_COMPLETED, { paymentMethod: 'apple_pay', applePayResult });
    // notify main app - TokenizationComplete event
    dispatchMessageToApp(CheckoutSdkFrameEventType.TokenizationComplete, {
      ...result,
      profilingAttemptReference,
    });
    const createApplePayPayInInput: CreateApplePayPaymentData = {
      PaymentData: {
        transactionId: applePayResult.transactionIdentifier,
        network: applePayResult.paymentMethod.network,
        tokenData: JSON.stringify(applePayResult.paymentData),
      },
      ProfilingAttemptReference: profilingAttemptReference,
    };
    if (options?.handleApplePayPayment) {
      setIsLoading(true);
      dispatchMessageToApp(CheckoutSdkFrameEventType.CreateApplePayPayIn, createApplePayPayInInput);
      window.addEventListener('message', handleCreateApplePayPayInComplete);
    } else {
      setIsLoading(false);
    }
    logEvent({ message: 'Tokenization completed' });
  };

  return {
    handleAuthorizeApplePayComplete,
  };
};
