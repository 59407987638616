export enum CheckoutSdkFrameEventType {
  Initialize = 'initialize',
  Change = 'change',
  Cancel = 'cancel',
  Error = 'error',
  Resize = 'resize',
  OpenHostAuthPopupWindow = 'openHostAuthPopupWindow',
  CloseHostAuthPopupWindow = 'closeHostAuthPopupWindow',
  OpenHostAuthPopupModal = 'openHostAuthPopupModal',
  CloseHostAuthPopupModal = 'closeHostAuthPopupModal',
  SetErrorMessage = 'setErrorMessage',
  PaymentMethodSelect = 'paymentMethodSelect',
  PaymentComplete = 'paymentComplete',
  TokenizationComplete = 'tokenizationComplete',
  GetGooglePayToken = 'getGooglePayToken',
  CreateCardRegistration = 'createCardRegistration',
  CreatePaypalPayment = 'createPaypalPayment',
  ShowPopupWindowOverlay = 'showPopupWindowOverlay',
  CreateCardPayment = 'createCardPayment',
  CreateGooglePayPayment = 'createGooglePayPayment',
  CreateApplePayPaymentRequest = 'createApplePayPaymentRequest',
  CreateApplePayPayIn = 'createApplePayPayIn',
}
